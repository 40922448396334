import {
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  DefaultCrypto,
  FetchRequestor,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  LocalStorageBackend,
  RedirectRequestHandler,
  TokenRequest,
  TokenResponse
} from '@ariyana/appauth';
import {
  setDoBootstrap,
  setProvideAuthorizationConfig
} from 'redux/slices/systemSlice';
import { getPostLoginCallbackRoute } from 'spec';
import { parseAccessToken } from '../utils/Utils';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import NoHashQueryStringUtils from './noHashQueryStringUtils';

const config = window.client;

const LOCAL_STORAGE_TOKEN_RESPONSE_JSON_KEY = 'tkrs';
const LOCAL_STORAGE_USER_INFO_KEY = 'profile';
const LOCAL_STORAGE_ARY_AUTH_KEY = 'aryauth';

export class AuthorizeService {
  tokenResponseJson;

  configuration;

  userInfo;

  constructor() {
    this.requestor = new FetchRequestor();
    this.tokenHandler = new BaseTokenRequestHandler(this.requestor);
    this.redirectRequestHandler = new RedirectRequestHandler();
    this.localStorageBackend = new LocalStorageBackend();
  }

  async setItemToStorage(key, value) {
    const itemsJson = await this.localStorageBackend.getItem(
      LOCAL_STORAGE_ARY_AUTH_KEY
    );
    const items = itemsJson ? JSON.parse(itemsJson) : {};
    const newItems = { ...items, [key]: value };
    await this.localStorageBackend.setItem(
      LOCAL_STORAGE_ARY_AUTH_KEY,
      JSON.stringify(newItems)
    );
  }

  async getItemFromStorage(key) {
    const itemsJson = await this.localStorageBackend.getItem(
      LOCAL_STORAGE_ARY_AUTH_KEY
    );
    if (!itemsJson) {
      return null;
    }
    const items = JSON.parse(itemsJson);
    const value = items[key];
    return value;
  }

  async fetchServiceConfiguration() {
    try {
      const resp = await AuthorizationServiceConfiguration.fetchFromIssuer(
        config.authority,
        this.requestor
      );
      this.configuration = resp;
      return resp;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getServiceConfiguration() {
    let { configuration } = this;
    if (!configuration) {
      configuration = await this.fetchServiceConfiguration();
    }
    return configuration;
  }

  async isAuthenticated() {
    const accessToken = await this.getAccessToken();
    return !!accessToken;
  }

  // ************************* get user info ******************************

  async fetchUserInfo() {
    const configuration = await this.getServiceConfiguration();
    const token = await this.getAccessToken();
    const res = await fetch(configuration.userInfoEndpoint, {
      headers: {
        authorization: `Bearer ${token}`
      }
    });
    const userInfo = await res.json();
    this.userInfo = userInfo;
    return userInfo;
  }

  async getUserInfo() {
    let { userInfo } = this;
    if (!userInfo) {
      const localStorageUserInfo = await this.getItemFromStorage(
        LOCAL_STORAGE_USER_INFO_KEY
      );
      if (localStorageUserInfo) {
        userInfo = localStorageUserInfo;
        this.userInfo = userInfo;
      } else {
        const resp = await this.fetchUserInfo();
        userInfo = resp;
        await this.setItemToStorage(LOCAL_STORAGE_USER_INFO_KEY, resp);
      }
    }
    return userInfo;
  }

  async getFullname() {
    const user = await this.getUserInfo();
    return user.fullname;
  }

  async getUserName() {
    const user = await this.getUserInfo();
    return user.name;
  }

  // *********************** end get user info ****************************
  // ****************************** sign in *******************************

  async makeAuthorizationRequest() {
    try {
      const configuration = await this.getServiceConfiguration();
      const request = new AuthorizationRequest({
        client_id: config.client_id,
        redirect_uri: config.redirect_uri,
        scope: config.scope,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: undefined
      });
      sessionStorage.setItem(
        'originalPath',
        `${window.location.pathname}${window.location.search}`
      );
      this.redirectRequestHandler.performAuthorizationRequest(
        configuration,
        request
      );
    } catch (error) {
      console.error(error);
    }
  }

  // ****************************** end sign in *******************************
  // ****************************** get refresh token *******************************

  fetchRefreshToken(code, navigate, dispatchToReduxStore) {
    const redirectRequestHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto()
    );
    const notifier = new AuthorizationNotifier();
    redirectRequestHandler.setAuthorizationNotifier(notifier);
    notifier.setAuthorizationListener(async (request, response, error) => {
      if (error) {
        console.error(error);
      }
      if (response) {
        let extras = null;
        if (request && request.internal) {
          extras = {};
          extras.code_verifier = request.internal.code_verifier;
        }

        const tokenRequest = new TokenRequest({
          client_id: config.client_id,
          redirect_uri: config.redirect_uri,
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code,
          refresh_token: undefined,
          extras
        });

        try {
          const configuration = await this.getServiceConfiguration();
          const tokenResponse = await this.tokenHandler.performTokenRequest(
            configuration,
            tokenRequest
          );
          const tokenResponseJson = tokenResponse.toJson();

          this.tokenResponseJson = tokenResponseJson;

          await this.setItemToStorage(
            LOCAL_STORAGE_TOKEN_RESPONSE_JSON_KEY,
            tokenResponseJson
          );
          dispatchToReduxStore(setProvideAuthorizationConfig(true));
          dispatchToReduxStore(setDoBootstrap(true));
          navigate(getPostLoginCallbackRoute(tokenResponse.accessToken));
        } catch (Oerror) {
          console.error(Oerror);
        }
      }
    });

    redirectRequestHandler.completeAuthorizationRequestIfPossible();
  }

  async getRefreshToken() {
    const { tokenResponseJson } = this;
    let tokenResponse;

    if (tokenResponseJson) {
      tokenResponse = new TokenResponse(tokenResponseJson);
    }

    if (!tokenResponse) {
      const localStorageTokenResponseJson = await this.getItemFromStorage(
        LOCAL_STORAGE_TOKEN_RESPONSE_JSON_KEY
      );
      if (localStorageTokenResponseJson) {
        const localStorageTokenResponse = new TokenResponse(
          localStorageTokenResponseJson
        );
        this.tokenResponseJson = localStorageTokenResponseJson;
        tokenResponse = localStorageTokenResponse;
      } else {
        return null;
      }
    }
    return tokenResponse ? tokenResponse.refreshToken : null;
  }

  // ****************************** end get refresh token *******************************
  // ****************************** get access token *******************************

  async fetchTokenResponseJson() {
    const configuration = await this.getServiceConfiguration();
    const refreshToken = await this.getRefreshToken();
    if (!refreshToken) {
      if (window.location.pathname !== ApplicationPaths.LoginCallback) {
        this.makeAuthorizationRequest();
      }
      return null;
    }
    const request = new TokenRequest({
      client_id: config.client_id,
      redirect_uri: config.redirect_uri,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      code: undefined,
      refresh_token: refreshToken,
      extras: undefined
    });

    try {
      const resp = await this.tokenHandler.performTokenRequest(
        configuration,
        request
      );

      const tokenResponseJson = resp.toJson();
      await this.setItemToStorage(
        LOCAL_STORAGE_TOKEN_RESPONSE_JSON_KEY,
        tokenResponseJson
      );
      this.tokenResponseJson = tokenResponseJson;
      return tokenResponseJson;
    } catch {
      this.signOut();
      return undefined;
    }
  }

  async getTokenResponseJson() {
    const isAccessTokenValid = (tokenResponseJson) => {
      if (!tokenResponseJson) return false;
      const tokenResponse = new TokenResponse(tokenResponseJson);
      return !!tokenResponse.accessToken && tokenResponse.isValid();
    };
    let { tokenResponseJson } = this;
    if (!isAccessTokenValid(tokenResponseJson)) {
      const localStorageTokenResponseJson = await this.getItemFromStorage(
        LOCAL_STORAGE_TOKEN_RESPONSE_JSON_KEY
      );
      if (isAccessTokenValid(localStorageTokenResponseJson)) {
        tokenResponseJson = localStorageTokenResponseJson;
        this.tokenResponseJson = tokenResponseJson;
      } else {
        tokenResponseJson = await this.fetchTokenResponseJson();
      }
    }
    if (!tokenResponseJson) return null;
    return tokenResponseJson;
  }

  async getAccessToken() {
    const tokenResponseJson = await this.getTokenResponseJson();
    let tokenResponse;
    if (tokenResponseJson) {
      tokenResponse = new TokenResponse(tokenResponseJson);
    }
    return tokenResponse ? tokenResponse.accessToken : null;
  }

  async getIdToken() {
    const tokenResponseJson = await this.getTokenResponseJson();
    let tokenResponse;
    if (tokenResponseJson) {
      tokenResponse = new TokenResponse(tokenResponseJson);
    }
    return tokenResponse ? tokenResponse.idToken : null;
  }

  async getVersionExpireTime() {
    const accessToken = await this.getAccessToken();
    return accessToken ? parseAccessToken(accessToken).exa : null;
  }

  // ****************************** end get access token *******************************
  // ****************************** get claims *******************************

  async getClaims() {
    const token = await this.getAccessToken();
    if (!token) {
      return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    const _token = JSON.parse(jsonPayload);
    if (_token.getBodyClaims) {
      return JSON.parse(_token.clms);
    }
    return _token;
  }

  async getBodyClaims() {
    const token = await this.getAccessToken();
    const base64Url = token.split('.')[1];
    return JSON.parse(decodeURIComponent(escape(window.atob(base64Url))));
  }

  async getRole() {
    const token = await this.getAccessToken();
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    const _token = JSON.parse(jsonPayload);
    return _token.role;
  }

  // ****************************** end get claims *******************************
  // ****************************** check session *******************************

  async checkSession() {
    const token = await this.getAccessToken();
    const resp = await fetch(`${config.authority}/api/Validity/Valid`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`
      }
    });
    return resp;
  }

  // ****************************** end check session *******************************
  // ****************************** sign out *******************************

  async signOut(state) {
    const configuration = await this.getServiceConfiguration();
    const idToken = await this.getIdToken();
    const paramsString = new URLSearchParams(`id_token_hint=${idToken}`);
    paramsString.append(
      'post_logout_redirect_uri',
      config.post_logout_redirect_uri
    );
    if (state) {
      const { returnUrl } = state;
      paramsString.append('state', returnUrl);
    }
    const url = `${configuration.endSessionEndpoint}?${paramsString}`;
    await this.localStorageBackend.removeItem(LOCAL_STORAGE_ARY_AUTH_KEY);
    this.tokenResponseJson = null;
    this.userInfo = null;
    window.location = url;
  }

  // ****************************** end sign out *******************************
}

const authService = new AuthorizeService();

export default authService;
