/* eslint-disable */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './lang-resources';

i18n.use(initReactI18next).init({
  resources,
  lng: 'fa',
  fallbackLng: 'fa',
  interpolation: {
    escapeValue: false
  }
});
