import { createSlice } from '@reduxjs/toolkit';

export const systemSlice = createSlice({
  name: 'systemSlice',
  initialState: {
    errors: [],
    noVersionError: false,
    provideAuthorizationConfig: false,
    doBootstrap: false
  },
  reducers: {
    setErrors: (state, action) => {
      state.errors = [].concat(action.payload);
    },
    setNoVersionError: (state, action) => {
      state.noVersionError = action.payload;
    },
    setProvideAuthorizationConfig: (state, action) => {
      state.provideAuthorizationConfig = action.payload;
    },
    setDoBootstrap: (state, action) => {
      state.doBootstrap = action.payload;
    }
  }
});

export const {
  setErrors,
  setNoVersionError,
  setProvideAuthorizationConfig,
  setDoBootstrap
} = systemSlice.actions;
export default systemSlice.reducer;
