import AutoNumeric from 'autonumeric';

function countDecimals(amount) {
  for (let i = 0; i < 20; i++) {
    if (Math.floor(amount * 10 ** i) === amount * 10 ** i) {
      return i;
    }
  }
  return null;
}

function commafy(num) {
  const str = num.toString().split('.');
  if (str[0].length >= 5) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join(',');
}

export function formatMoneyAmount(amount, decimalPlaces) {
  if (amount > 10000000000000) return commafy(amount);

  return AutoNumeric.format(amount, {
    digitGroupSeparator: ',',
    decimalPlaces:
      decimalPlaces !== undefined
        ? Math.min(decimalPlaces, countDecimals(amount))
        : countDecimals(amount)
  });
}

export default { formatMoneyAmount };
