const persianTypography = (layoutType) => ({
  fontFamily: [
    'YekanBakhFaNum',
    '-apple-system',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  button: {
    textTransform: 'none'
  },
  header36: {
    fontSize: '36px',
    fontWeight: '900',
    lineHeight: '40px'
  },
  // ********************************** new Typographies ******************************************
  // "h" stands for header
  h1Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '28px'
        : layoutType === 'laptop'
        ? '26px'
        : layoutType === 'tablet'
        ? '24px'
        : '28px',
    fontWeight: '900',
    lineHeight: '40px'
  },
  h2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '24px'
        : layoutType === 'laptop'
        ? '22px'
        : layoutType === 'tablet'
        ? '20px'
        : '24px',
    fontWeight: '900',
    lineHeight: '28px'
  },
  h3Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '20px'
        : layoutType === 'laptop'
        ? '18px'
        : layoutType === 'tablet'
        ? '16px'
        : '20px',
    fontWeight: '900',
    lineHeight: '28px'
  },
  h3Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '20px'
        : layoutType === 'laptop'
        ? '18px'
        : layoutType === 'tablet'
        ? '16px'
        : '20px',
    fontWeight: '500',
    lineHeight: '28px'
  },
  h4Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '18px'
        : layoutType === 'laptop'
        ? '16px'
        : layoutType === 'tablet'
        ? '14px'
        : '18px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  h4Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '18px'
        : layoutType === 'laptop'
        ? '16px'
        : layoutType === 'tablet'
        ? '14px'
        : '18px',
    fontWeight: '500',
    lineHeight: '24px'
  },
  h5Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '16px'
        : layoutType === 'laptop'
        ? '14px'
        : layoutType === 'tablet'
        ? '12px'
        : '16px',
    fontWeight: '900',
    lineHeight: '20px'
  },
  h5Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '16px'
        : layoutType === 'laptop'
        ? '14px'
        : layoutType === 'tablet'
        ? '12px'
        : '16px',
    fontWeight: '700',
    lineHeight: '20px'
  },
  h5Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '16px'
        : layoutType === 'laptop'
        ? '14px'
        : layoutType === 'tablet'
        ? '12px'
        : '16px',
    fontWeight: '500',
    lineHeight: '20px'
  },
  h6Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '900',
    lineHeight: '18px'
  },
  h6Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '700',
    lineHeight: '18px'
  },
  h6Medium: {
    fontSize: layoutType === 'desktop' ? '14px' : '12px',
    fontWeight: '500',
    lineHeight: '16px'
  },
  h7Heavy: {
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '14px'
  },
  h7Bold: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '14px'
  },
  h7Medium: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px'
  },
  // "c" stands for common
  c1Heavy: {
    fontSize: '16px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  c1Bold: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  c1Medium: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px'
  },
  c2Bold: {
    fontSize: layoutType === 'desktop' ? '14px' : '12px',
    fontWeight: '700',
    lineHeight: '18px'
  },
  c2Medium: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px'
  },
  c3Heavy: {
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '14px'
  },
  c3Bold: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '18px'
  },
  c3Medium: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px'
  },
  c4Bold: {
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '12px'
  },
  c4Medium: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '12px'
  },
  // "t" stands for table
  t1Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '16px'
        : layoutType === 'laptop'
        ? '14px'
        : layoutType === 'tablet'
        ? '12px'
        : '16px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  t2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  t2Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  t3Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '12px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '12px',
    fontWeight: '700',
    lineHeight: '20px'
  },
  chipBold: {
    fontSize:
      layoutType === 'desktop'
        ? '12px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '12px',
    fontWeight: '700',
    lineHeight: '16px'
  },
  // "b" stands for button
  b1Heavy: {
    fontSize: '18px',
    fontWeight: '900',
    lineHeight: '28px'
  },
  b2Heavy: {
    fontSize: '16px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  b3Heavy: {
    fontSize: '14px',
    fontWeight: '900',
    lineHeight: '16px'
  },
  b4Heavy: {
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '16px'
  },
  // "i" stands for input
  i1Bold: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  i2Heavy: {
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '16px'
  },
  i2Bold: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16px'
  },
  i2Medium: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px'
  },
  labelMedium: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '16px'
  }
  // ********************************** end new Typographies ******************************************
});

const persianWithEnglishNumberTypography = (layoutType) => ({
  fontFamily: [
    'YekanBakh',
    '-apple-system',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  button: {
    textTransform: 'none'
  },
  header36: {
    fontSize: '36px',
    fontWeight: '900',
    lineHeight: '40px'
  },
  // ********************************** new Typographies ******************************************
  // "h" stands for header
  h1Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '28px'
        : layoutType === 'laptop'
        ? '26px'
        : layoutType === 'tablet'
        ? '24px'
        : '28px',
    fontWeight: '900',
    lineHeight: '40px'
  },
  h2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '24px'
        : layoutType === 'laptop'
        ? '22px'
        : layoutType === 'tablet'
        ? '20px'
        : '24px',
    fontWeight: '900',
    lineHeight: '28px'
  },
  h3Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '20px'
        : layoutType === 'laptop'
        ? '18px'
        : layoutType === 'tablet'
        ? '16px'
        : '20px',
    fontWeight: '900',
    lineHeight: '28px'
  },
  h3Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '20px'
        : layoutType === 'laptop'
        ? '18px'
        : layoutType === 'tablet'
        ? '16px'
        : '20px',
    fontWeight: '500',
    lineHeight: '28px'
  },
  h4Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '18px'
        : layoutType === 'laptop'
        ? '16px'
        : layoutType === 'tablet'
        ? '14px'
        : '18px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  h4Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '18px'
        : layoutType === 'laptop'
        ? '16px'
        : layoutType === 'tablet'
        ? '14px'
        : '18px',
    fontWeight: '500',
    lineHeight: '24px'
  },
  h5Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '16px'
        : layoutType === 'laptop'
        ? '14px'
        : layoutType === 'tablet'
        ? '12px'
        : '16px',
    fontWeight: '900',
    lineHeight: '20px'
  },
  h5Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '16px'
        : layoutType === 'laptop'
        ? '14px'
        : layoutType === 'tablet'
        ? '12px'
        : '16px',
    fontWeight: '700',
    lineHeight: '20px'
  },
  h6Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '900',
    lineHeight: '18px'
  },
  h6Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '700',
    lineHeight: '18px'
  },
  h6Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '500',
    lineHeight: '16px'
  },
  h7Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '12px'
        : layoutType === 'laptop'
        ? '10px'
        : layoutType === 'tablet'
        ? '10px'
        : '12px',
    fontWeight: '900',
    lineHeight: '14px'
  },
  h7Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '12px'
        : layoutType === 'laptop'
        ? '10px'
        : layoutType === 'tablet'
        ? '10px'
        : '12px',
    fontWeight: '700',
    lineHeight: '14px'
  },
  // "c" stands for common
  c1Heavy: {
    fontSize: '16px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  c1Bold: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  c1Medium: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px'
  },
  c2Bold: {
    fontSize: layoutType === 'desktop' ? '14px' : '12px',
    fontWeight: '700',
    lineHeight: '18px'
  },
  c2Medium: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px'
  },
  c3Heavy: {
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '14px'
  },
  c3Bold: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '18px'
  },
  c3Medium: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px'
  },
  c4Bold: {
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '12px'
  },
  c4Medium: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '12px'
  },
  // "t" stands for table
  t1Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '16px'
        : layoutType === 'laptop'
        ? '14px'
        : layoutType === 'tablet'
        ? '12px'
        : '16px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  t2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  t2Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  t3Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '12px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '12px',
    fontWeight: '700',
    lineHeight: '20px'
  },
  chipBold: {
    fontSize:
      layoutType === 'desktop'
        ? '12px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '12px',
    fontWeight: '700',
    lineHeight: '16px'
  },
  // "b" stands for button
  b1Heavy: {
    fontSize: '18px',
    fontWeight: '900',
    lineHeight: '28px'
  },
  b2Heavy: {
    fontSize: '16px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  b3Heavy: {
    fontSize: '14px',
    fontWeight: '900',
    lineHeight: '16px'
  },
  b4Heavy: {
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '16px'
  },
  // "i" stands for input
  i1Bold: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  i2Heavy: {
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '16px'
  },
  i2Bold: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16px'
  },
  i2Medium: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px'
  },
  labelMedium: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '16px'
  }
  // ********************************** end new Typographies ******************************************
});

const englishTypography = (layoutType) => ({
  fontFamily: [
    'Inter',
    '-apple-system',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  button: {
    textTransform: 'none'
  },
  header36: {
    fontSize: '36px',
    fontWeight: '900',
    lineHeight: '40px'
  },
  // ********************************** new Typographies ******************************************
  // "h" stands for header
  h1Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '28px'
        : layoutType === 'laptop'
        ? '26px'
        : layoutType === 'tablet'
        ? '24px'
        : '28px',
    fontWeight: '900',
    lineHeight: '40px'
  },
  h2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '24px'
        : layoutType === 'laptop'
        ? '22px'
        : layoutType === 'tablet'
        ? '20px'
        : '24px',
    fontWeight: '900',
    lineHeight: '28px'
  },
  h3Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '20px'
        : layoutType === 'laptop'
        ? '18px'
        : layoutType === 'tablet'
        ? '16px'
        : '20px',
    fontWeight: '900',
    lineHeight: '28px'
  },
  h3Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '20px'
        : layoutType === 'laptop'
        ? '18px'
        : layoutType === 'tablet'
        ? '16px'
        : '20px',
    fontWeight: '500',
    lineHeight: '28px'
  },
  h4Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '18px'
        : layoutType === 'laptop'
        ? '16px'
        : layoutType === 'tablet'
        ? '14px'
        : '18px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  h4Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '18px'
        : layoutType === 'laptop'
        ? '16px'
        : layoutType === 'tablet'
        ? '14px'
        : '18px',
    fontWeight: '500',
    lineHeight: '24px'
  },
  h5Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '16px'
        : layoutType === 'laptop'
        ? '14px'
        : layoutType === 'tablet'
        ? '12px'
        : '16px',
    fontWeight: '900',
    lineHeight: '20px'
  },
  h5Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '16px'
        : layoutType === 'laptop'
        ? '14px'
        : layoutType === 'tablet'
        ? '12px'
        : '16px',
    fontWeight: '700',
    lineHeight: '20px'
  },
  h6Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '900',
    lineHeight: '18px'
  },
  h6Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '700',
    lineHeight: '18px'
  },
  h6Medium: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '500',
    lineHeight: '16px'
  },
  h7Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '12px'
        : layoutType === 'laptop'
        ? '10px'
        : layoutType === 'tablet'
        ? '10px'
        : '12px',
    fontWeight: '900',
    lineHeight: '14px'
  },
  h7Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '12px'
        : layoutType === 'laptop'
        ? '10px'
        : layoutType === 'tablet'
        ? '10px'
        : '12px',
    fontWeight: '700',
    lineHeight: '14px'
  },
  // "c" stands for common
  c1Heavy: {
    fontSize: '16px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  c1Bold: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  c1Medium: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px'
  },
  c2Bold: {
    fontSize: layoutType === 'desktop' ? '14px' : '12px',
    fontWeight: '700',
    lineHeight: '18px'
  },
  c2Medium: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px'
  },
  c3Heavy: {
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '14px'
  },
  c3Bold: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '18px'
  },
  c3Medium: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px'
  },
  c4Bold: {
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '12px'
  },
  c4Medium: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '12px'
  },
  // "t" stands for table
  t1Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '16px'
        : layoutType === 'laptop'
        ? '14px'
        : layoutType === 'tablet'
        ? '12px'
        : '16px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  t2Heavy: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  t2Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '14px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '14px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  t3Bold: {
    fontSize:
      layoutType === 'desktop'
        ? '12px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '12px',
    fontWeight: '700',
    lineHeight: '20px'
  },
  chipBold: {
    fontSize:
      layoutType === 'desktop'
        ? '12px'
        : layoutType === 'laptop'
        ? '12px'
        : layoutType === 'tablet'
        ? '12px'
        : '12px',
    fontWeight: '700',
    lineHeight: '16px'
  },
  // "b" stands for button
  b1Heavy: {
    fontSize: '18px',
    fontWeight: '900',
    lineHeight: '28px'
  },
  b2Heavy: {
    fontSize: '16px',
    fontWeight: '900',
    lineHeight: '24px'
  },
  b3Heavy: {
    fontSize: '14px',
    fontWeight: '900',
    lineHeight: '16px'
  },
  b4Heavy: {
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '16px'
  },
  // "i" stands for input
  i1Bold: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '24px'
  },
  i2Heavy: {
    fontSize: '12px',
    fontWeight: '900',
    lineHeight: '16px'
  },
  i2Bold: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16px'
  },
  i2Medium: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px'
  },
  labelMedium: {
    fontSize: '10px',
    fontWeight: '500',
    lineHeight: '16px'
  }
  // ********************************** end new Typographies ******************************************
});

export {
  persianTypography,
  englishTypography,
  persianWithEnglishNumberTypography
};
