const paddingLaptop = {
  mainContent: '18px 24px',
  aryCard: '21px 18px',
  aryWrapper: '14px 18px',
  aryWrapperY: '18px',
  aryWrapperX: '24px',
  gridRowSpacing: '18px',
  gridSmallRowSpacing: '16px',
  gridColumnSpacing: '12px',
  gridSmallColumnSpacing: '8px',
  aryAlertBottom: '32px'
};

export default paddingLaptop;
