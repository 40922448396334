import createCache from '@emotion/cache';
import {
  CacheProvider,
  ThemeProvider as StyledThemeProvider
} from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import LayoutTypeContext from 'framework/base/contexts/LayoutTypeContext';
import useAryLayoutType from 'framework/base/hooks/useAryLayoutType';
import React, { lazy, Suspense, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthRouteGuard as AuthGuard, LoginCallbackHandler } from 'spec';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import './assets/fontYekanBakh.css';
import createTheme from './framework/base/theme/index';
import useTheme from './framework/base/theme/useTheme';
import './language/translation';
import appRoutes from './routes';

const AryNoVersionModal = lazy(() =>
  import('framework/base/components/AryNoVersionModal')
);

// const DEFAULT_LANGUAGE = 'fa';

const cacheRtl = createCache({
  key: 'muirtl',
  prepend: true,
  stylisPlugins: [prefixer, rtlPlugin]
});

const DirectionProvider = ({ rtl = false, children }) => {
  if (!rtl) {
    return children;
  }
  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

function lazyRoutes(routes, layoutType) {
  if (!routes || routes.length === 0) return null;
  return (
    <>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AuthGuard
              anonymousAccess={route.anonymousAccess}
              authKeys={route.authKeys}
              path={route.path}
              component={
                layoutType === 'mobile' && route.mobile
                  ? route.mobile
                  : route.component
              }
            />
          }
        >
          {lazyRoutes(route.routes, layoutType)}
        </Route>
      ))}
    </>
  );
}

const App = () => {
  const { t, i18n } = useTranslation();
  // const reduxStore = useStore();
  const { theme } = useTheme();
  const reduxStore = useStore();
  const langDirection = i18n.dir();
  // const [AryAlert, showAlert] = useAryAlert();
  const layoutType = useAryLayoutType();
  const appTheme = createTheme(theme, undefined, langDirection, layoutType);
  document.body.dir = langDirection;
  const [noVersionModalOpen, setNoVersionModalOpen] = useState(false);

  // const [getDescriptionTemplates, { data: descriptionTemplates }] =
  //   useAryLazyQuery({
  //     gqlConfig: GET_DESCRIPTION_TEMPLATES,
  //     items: `
  //   id
  //   body
  //   `
  //   });

  // const parseDescription = useTemplateParsing(descriptionTemplates);

  // reduxStore.subscribe(() => {
  //   reduxStore
  //     .getState()
  //     .errorReducer.errors.map(
  //       (error, index) =>
  //         index === 0 &&
  //         showAlert(
  //           AryAlertTypes.ERROR,
  //           parseDescription(error.templateId, error.metadata)
  //         )
  //     );
  // });

  reduxStore.subscribe(() => {
    const { noVersionError } = reduxStore.getState().systemReducer;
    if (noVersionError) setNoVersionModalOpen(true);
  });

  // useEffect(() => {
  //   getDescriptionTemplates(DEFAULT_LANGUAGE);
  // }, []);

  // if (!descriptionTemplates?.length) {
  //   return <AryLoadingAnimation />;
  // }

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={` ${t('Ariyana')} | %s`}
        defaultTitle={`${t('Ariyana')} | ${t('Charge Management Panel')}`}
      />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={appTheme}>
          <StyledThemeProvider theme={appTheme}>
            <DirectionProvider rtl={langDirection === 'rtl'}>
              <LayoutTypeContext.Provider value={layoutType}>
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate replace to="/dashboard/accounts" />}
                  />
                  <Route
                    path="authentication/login-callback"
                    element={<LoginCallbackHandler />}
                  />
                  {lazyRoutes(appRoutes, layoutType)}
                </Routes>
                {/* {AryAlert} */}
                {noVersionModalOpen && (
                  <Suspense fallback="">
                    <AryNoVersionModal open={noVersionModalOpen} />
                  </Suspense>
                )}
              </LayoutTypeContext.Provider>
            </DirectionProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
};

export default App;
