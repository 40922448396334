import { lazy } from 'react';

const routes = [
  {
    path: '/dashboard',
    component: lazy(() => import('./Dashboard')),
    anonymousAccess: true,
    routes: [
      {
        path: 'errors/error-401',
        component: lazy(() => import('./pages/desktop/errors/Error401'))
      },
      {
        path: 'reporting',
        component: lazy(() => import('./pages/desktop/Reporting')),
        mobile: lazy(() => import('./pages/mobile/Reporting'))
      },
      {
        path: 'accounts',
        component: lazy(() => import('./pages/desktop/Accounts')),
        mobile: lazy(() => import('./pages/mobile/Accounts'))
      },
      {
        path: 'customers',
        component: lazy(() => import('./pages/desktop/Customers')),
        mobile: lazy(() => import('./pages/mobile/Customers'))
      },
      {
        path: 'place-of-payment',
        component: lazy(() => import('./pages/desktop/PlaceOfPayment')),
        mobile: lazy(() => import('./pages/mobile/PlaceOfPayment'))
      },
      {
        path: 'rates',
        component: lazy(() => import('./pages/desktop/Rates')),
        mobile: lazy(() => import('./pages/mobile/Rates'))
      }
    ]
  },
  {
    path: '/*',
    component: lazy(() => import('./pages/desktop/errors/Error404'))
  }
];

export default routes;
