import imageCompression from 'browser-image-compression';
import moment from 'jalali-moment';
import { getQueryStringsFromLocation, openNewWindow } from 'spec';
import { readFile } from 'xlsx';
import { formatMoneyAmount } from '../components/AryNumberFormatter';

// ***********************************************************************
//
// xlsx formatters
//
// ***********************************************************************

export const saderatFormatterSettings = {
  row: {
    columns: ['A'],
    parse: ([a]) => a
  },
  date: {
    columns: ['B', 'C'],
    parse: ([date, time]) => {
      const clearDate = date.trim();
      const clearTime = time.trim();
      const dateAndTime = moment.from(
        `${clearDate} ${clearTime}`,
        'fa',
        'YYYY/M/D HH:mm:ss'
      );
      return new Date(+dateAndTime).getTime().toString();
    }
  },
  branchCode: {
    columns: ['D'],
    parse: ([branchCode]) => branchCode.trim()
  },
  transactionType: {
    columns: ['E'],
    parse: ([transactionType]) => transactionType.trim()
  },
  receiptNumber: {
    columns: ['F'],
    parse: ([receiptNumber]) => receiptNumber.trim().replace(/ /g, '')
  },
  description: {
    columns: ['G'],
    parse: ([description]) => description.trim()
  },
  notes: {
    columns: ['E', 'G'],
    parse: ([transactionType, description]) =>
      `${transactionType.trim()} - ${description.trim()}`
  },
  deposit: {
    columns: ['H'],
    parse: ([deposit]) => +deposit.replace(/,/g, '').trim()
  },
  withdraw: {
    columns: ['I'],
    parse: ([withdraw]) => +withdraw.replace(/,/g, '').trim()
  },
  amount: {
    columns: ['H', 'I'],
    parse: ([deposit, withdraw]) =>
      +deposit.replace(/,/g, '').trim() !== 0
        ? +deposit.replace(/,/g, '').trim()
        : +withdraw.replace(/,/g, '').trim()
  },
  isDeposit: {
    columns: ['H'],
    parse: ([deposit]) => !!+deposit.replace(/,/g, '').trim()
  },
  balance: {
    columns: ['J'],
    parse: ([balance]) => +balance.replace(/,/g, '').trim()
  }
};

// ***********************************************************************
//
// date functions
//
// ***********************************************************************

export function getMoonUtcTime(date) {
  if (date === null) return null;
  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getDate(),
    12,
    0,
    0,
    0
  ).toString();
}

export function getTimezoneBasedNightTime(myDate) {
  if (myDate === null) return null;
  const date = new Date(myDate);
  date.setFullYear(myDate.getFullYear());
  date.setMonth(myDate.getMonth());
  date.setDate(myDate.getDate());
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date.getTime().toString();
}

export function getUtcTime(date) {
  if (date === null) return null;
  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    0,
    0
  ).toString();
}

export function getTimeZoneFormattedDate(date, isGregorian, timeZone) {
  const locale = isGregorian ? 'en-ZA' : 'fa-IR';
  return new Date(+date).toLocaleDateString(locale, {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
}

export function getTimeZoneFormattedTime(date, isGregorian, timeZone) {
  const formattedDate = getTimeZoneFormattedDate(date, isGregorian, timeZone);
  const locale = isGregorian ? 'en-ZA' : 'fa-IR';
  const formattedTime = new Date(+date).toLocaleTimeString(locale, {
    timeZone,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });
  return `${formattedDate} - ${formattedTime}`;
}

export function getPastOrFutureYear(yearCount, date = new Date().getTime()) {
  const year = new Date(date).Year;
  const dayCount = year % 4 === 0 ? 366 : 365;
  const dayTimeStamp = 24 * 3600 * 1000;
  return date + yearCount * dayCount * dayTimeStamp;
}

export function getMonthName(date, isGregorian, timeZone) {
  const locale = isGregorian ? 'en-US' : 'fa-IR';
  return new Date(+date).toLocaleTimeString(locale, {
    timeZone,
    month: 'long'
  });
}

// ***********************************************************************
//
// function for convert persian and arabic numbers to english
//
// ***********************************************************************

export function fixNumbers(input) {
  return input;
  // const str = input.toString();
  // let finalStr;
  // const persianNumbers = [
  //   /۰/g,
  //   /۱/g,
  //   /۲/g,
  //   /۳/g,
  //   /۴/g,
  //   /۵/g,
  //   /۶/g,
  //   /۷/g,
  //   /۸/g,
  //   /۹/g
  // ];
  // const arabicNumbers = [
  //   /٠/g,
  //   /١/g,
  //   /٢/g,
  //   /٣/g,
  //   /٤/g,
  //   /٥/g,
  //   /٦/g,
  //   /٧/g,
  //   /٨/g,
  //   /٩/g
  // ];
  // for (let i = 0; i < 10; i++) {
  //   finalStr = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
  // }
  // return finalStr;
}

// ***********************************************************************
//
// file compressor
//
// ***********************************************************************

export async function compressFile(fileOrBlob) {
  try {
    if (
      fileOrBlob.type !== 'application/pdf' &&
      fileOrBlob.type !== 'application/msword' &&
      fileOrBlob.type !==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
      fileOrBlob.type !== 'application/vnd.ms-excel' &&
      fileOrBlob.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      const compressedBlob = await imageCompression(fileOrBlob, {
        initialQuality: 0.3
      });
      return new File([compressedBlob], compressedBlob.name, {
        type: compressedBlob.type
      });
    }
    return fileOrBlob;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function compressFileForProfile(fileOrBlob, maxSizeInKB = 3) {
  try {
    if (
      fileOrBlob.type !== 'application/pdf' &&
      fileOrBlob.type !== 'application/msword' &&
      fileOrBlob.type !==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
      fileOrBlob.type !== 'application/vnd.ms-excel' &&
      fileOrBlob.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      const compressedBlob = await imageCompression(fileOrBlob, {
        initialQuality: 0.3,
        maxSizeMB: maxSizeInKB / 1024
      });
      return new File([compressedBlob], compressedBlob.name, {
        type: compressedBlob.type
      });
    }
    return fileOrBlob;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function compressFiles(filesBlob) {
  try {
    const compressedFilesPromises = [];
    for (let i = 0; i < filesBlob.length; i++) {
      compressedFilesPromises.push(compressFile(filesBlob[i]));
    }
    const compressedFileBlobs = await Promise.all(compressedFilesPromises);
    const compressedFiles = compressedFileBlobs.map(
      (blob) => new File([blob], blob.name, { type: blob.type })
    );
    return compressedFiles;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function getImageSrc(fileOrBlobOrBase64) {
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  if (base64regex.test(fileOrBlobOrBase64)) {
    return `data:image/png;base64,${fileOrBlobOrBase64}`;
  }
  const objectURL = URL.createObjectURL(fileOrBlobOrBase64);
  return objectURL;
}

export function getBlobImage(fileBlob) {
  const objectURL = URL.createObjectURL(fileBlob);
  const myImage = new Image();
  myImage.src = objectURL;
  return myImage;
}

// ***********************************************************************
//
// file functions
//
// ***********************************************************************

export function getFileNameFromFileId(fileId) {
  const splittedArray = fileId.split('_');
  splittedArray.shift();
  return splittedArray.join('_');
}

export function downloadFileInBrowser(blob, fileName) {
  const file = new File([blob], fileName, {
    type: blob.type
  });
  const objectURL = URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = objectURL;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// ***********************************************************************
//
// functions for strings
//
// ***********************************************************************

export function limitCharacters(text, maxChar) {
  let newText = text.trim();
  if (newText.length <= maxChar) return newText;

  newText = newText.substr(0, maxChar + 1);
  newText = newText.substr(0, newText.lastIndexOf(' '));
  return `${newText}...`;
}

export function translatePhraseItem(t, phraseItem) {
  if (!phraseItem || !t) return null;
  const finalText = phraseItem.shouldTranslate
    ? t(phraseItem.text.toLowerCase().trim())
    : phraseItem.text;
  return finalText;
}

export function generateTransactionDescription(t, tdArr) {
  let td = '';
  tdArr?.forEach((element) => {
    td = td.concat(translatePhraseItem(t, element));
    td = td.concat(' ');
  });
  return td.trim();
}

// ***********************************************************************
//
// xlsx functions
//
// ***********************************************************************

export function getDataFromExcelFile(file, formatter) {
  const workbook = readFile(file);
  const numberOfSheets = workbook.SheetNames.length;
  const transactions = [];

  const getExcelFileFirstRow = (worksheet, columnOfRow) => {
    let firstRow;
    try {
      for (const z in worksheet) {
        if (z.toString()[0] === columnOfRow) {
          if (worksheet[z].v === '1') {
            firstRow = parseInt(z.substring(1));
            return firstRow;
          }
        }
      }
      if (!firstRow) {
        throw new Error(`The first Row of sheet ${worksheet} is not defined!`);
      }
      return null;
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  };

  const getExcelFileLastRow = (worksheet, firstRow, columnOfRow) => {
    let lastRow;

    try {
      for (const z in worksheet) {
        if (z.toString()[0] === columnOfRow) {
          if (
            parseInt(parseInt(worksheet[z].v) + 1) !==
              parseInt(
                worksheet[z[0] + parseInt(parseInt(z.substring(1)) + 1)]?.v
              ) &&
            parseInt(z.substring(1)) > firstRow
          ) {
            lastRow = parseInt(z.substring(1));
            return lastRow;
          }
        }
      }
      if (!lastRow) {
        throw new Error(`The last Row of sheet ${worksheet} is not defined!`);
      }
      return null;
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  };

  for (
    let currentWorksheet = 0;
    currentWorksheet < numberOfSheets;
    currentWorksheet++
  ) {
    const worksheet = workbook.Sheets[workbook.SheetNames[currentWorksheet]];
    const firstRow = getExcelFileFirstRow(worksheet, formatter.row.columns[0]);
    const lastRow = getExcelFileLastRow(
      worksheet,
      firstRow,
      formatter.row.columns[0]
    );

    for (let currentRow = firstRow; currentRow <= lastRow; currentRow++) {
      const dataKeys = Object.keys(formatter);
      const transaction = {};
      for (let index = 0; index < dataKeys.length; index++) {
        const key = dataKeys[index];
        const worksheetResult = formatter[key].columns.map(
          (column) => worksheet[column + currentRow].v
        );
        const value = formatter[key].parse(worksheetResult);
        transaction[key] = value;
      }
      delete transaction.row;
      delete transaction.transactionType;
      delete transaction.deposit;
      delete transaction.withdraw;
      delete transaction.balance;
      delete transaction.description;
      transactions.push(transaction);
    }
  }

  return transactions;
}

// ***********************************************************************
//
// calculation of source and destination
// currencies according to their dollar rate
//
// ***********************************************************************

export function getSourceToDestRate(
  sourceDollarRate,
  sourceIsDivision,
  destinationDollarRate,
  destinationIsDivision
) {
  const sourceToDollarRate = sourceIsDivision
    ? 1 / sourceDollarRate
    : sourceDollarRate;
  const destinationToDollarRate = destinationIsDivision
    ? 1 / destinationDollarRate
    : destinationDollarRate;
  return sourceToDollarRate / destinationToDollarRate;
}

export function convertCalculationRateToSourceAndDestinationAmounts(
  calculationRate,
  sourceToDestRate
) {
  const sourceAmount = 10 ** calculationRate;
  return { sourceAmount, destinationAmount: sourceToDestRate * sourceAmount };
}

// ***********************************************************************
//
// calculation of tertiary components
//
// ***********************************************************************

export function calculateFinalAmount(
  tradeAmount,
  tradeRate,
  calcRate,
  isDivision
) {
  if (isDivision && tradeRate) {
    return (tradeAmount * 10 ** calcRate) / tradeRate;
  }
  if (!isDivision && calcRate !== undefined) {
    return (tradeAmount * tradeRate) / 10 ** calcRate;
  }
  return null;
}

export function calculateTradeRate(
  tradeAmount,
  finalAmount,
  calcRate,
  isDivision
) {
  if (isDivision && finalAmount) {
    return (tradeAmount * 10 ** calcRate) / finalAmount;
  }
  if (!isDivision && tradeAmount) {
    return (finalAmount * 10 ** calcRate) / tradeAmount;
  }
  return null;
}

export function calculateTradeAmount(
  tradeRate,
  finalAmount,
  calcRate,
  isDivision
) {
  if (isDivision && calcRate !== undefined) {
    return (finalAmount * tradeRate) / 10 ** calcRate;
  }
  if (!isDivision && tradeRate) {
    return (finalAmount * 10 ** calcRate) / tradeRate;
  }
  return null;
}

export function handleChangeFirstTertiaryComponent({
  lockedLabel,
  setValues,
  numberValue,
  initial,
  tradeRate,
  result,
  calculationRate,
  isDivision
}) {
  if (!lockedLabel) {
    setValues({ [initial.name]: numberValue });
  } else if (lockedLabel === tradeRate.lockedLabel) {
    const resultAmount = calculateFinalAmount(
      numberValue,
      tradeRate.amount,
      calculationRate,
      isDivision
    );
    if (resultAmount < 10000000000000) {
      setValues({
        [initial.name]: numberValue,
        [result.name]: resultAmount || ''
      });
    } else if (resultAmount > 10000000000000) {
      setValues({ [initial.name]: numberValue });
      alert('مبلغ معادل حواله بیش از حد مجاز است');
    }
  } else if (lockedLabel === result.lockedLabel) {
    const rateAmount = calculateTradeRate(
      numberValue,
      result.amount,
      calculationRate,
      isDivision
    );
    setValues({
      [initial.name]: numberValue,
      [tradeRate.name]: rateAmount || ''
    });
  }
}

export function handleChangeSecondTertiaryComponent({
  lockedLabel,
  setValues,
  numberValue,
  initial,
  tradeRate,
  result,
  calculationRate,
  isDivision
}) {
  if (!lockedLabel) {
    setValues({ [tradeRate.name]: numberValue });
  } else if (lockedLabel === initial.lockedLabel) {
    const resultAmount = calculateFinalAmount(
      initial.amount,
      numberValue,
      calculationRate,
      isDivision
    );
    if (resultAmount < 10000000000000) {
      setValues({
        [tradeRate.name]: numberValue,
        [result.name]: resultAmount || ''
      });
    } else if (resultAmount > 10000000000000) {
      setValues({ [tradeRate.name]: numberValue });
      alert('مبلغ معادل حواله بیش از حد مجاز است');
    }
  } else if (lockedLabel === result.lockedLabel) {
    const initialAmount = calculateTradeAmount(
      numberValue,
      result.amount,
      calculationRate,
      isDivision
    );
    setValues({
      [tradeRate.name]: numberValue,
      [initial.name]: initialAmount || ''
    });
  }
}

export function handleChangeThirdTertiaryComponent({
  lockedLabel,
  setValues,
  numberValue,
  initial,
  tradeRate,
  result,
  calculationRate,
  isDivision
}) {
  if (!lockedLabel) {
    setValues({ [result.name]: numberValue });
  } else if (
    lockedLabel === initial.lockedLabel ||
    (lockedLabel === result.lockedLabel && initial.amount)
  ) {
    const rateAmount = calculateTradeRate(
      initial.amount,
      numberValue,
      calculationRate,
      isDivision
    );
    setValues({
      [result.name]: numberValue,
      [tradeRate.name]: rateAmount || ''
    });
  } else if (lockedLabel === tradeRate.lockedLabel) {
    const initialAmount = calculateTradeAmount(
      tradeRate.amount,
      numberValue,
      calculationRate,
      isDivision
    );
    setValues({
      [result.name]: numberValue,
      [initial.name]: initialAmount || ''
    });
  }
}

// ***********************************************************************
//
// utility functions
//
// ***********************************************************************

// remove elements that desired fields was empty in components like phoneNumber or accounts
export function clearExtendableArray(array, conditions) {
  if (!array || array.length === 0) return array;
  const newArray = array.slice();

  for (let index = array.length - 1; index >= 0; index--) {
    const element = array[index];
    if (conditions) {
      if (conditions.or?.length) {
        for (const field of conditions.or) {
          if (field.length) {
            if (element[field.name].length === field.is) {
              newArray.splice(index, 1);
              break;
            }
          } else if (element[field.name] === field.is) {
            newArray.splice(index, 1);
            break;
          }
        }
      } else if (conditions.and?.length) {
        for (const field of conditions.and) {
          if (field.length) {
            if (element[field.name].length !== field.is) {
              break;
            }
          } else if (element[field.name] !== field.is) {
            break;
          }
          if (field === conditions.and[conditions.and.length - 1]) {
            newArray.splice(index, 1);
          }
        }
      }
    } else if (!element && element !== 0) {
      newArray.splice(index, 1);
    }
  }
  return newArray;
}

export function formatQueryString(queryStringsList) {
  let formattedData = '';

  if (!queryStringsList?.length) return '';
  const clearQueryStringsList = queryStringsList.filter((obj) => obj.value);
  const formattedQueryStringsList = clearQueryStringsList.map(
    (item) => `${item.key}=${item.value}`
  );

  formattedData = formattedQueryStringsList.join('&');

  return formattedData ? `?${formattedData}` : '';
}

export function getQueryStringVariable(variable) {
  const query = getQueryStringsFromLocation();
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
}

export function createNewReportTab(path, queryStringsArray = []) {
  if (!path) return;
  const queryStrings = formatQueryString(queryStringsArray);
  openNewWindow(
    `${window.paths.rptf || window.paths.rptw}${path}${queryStrings}`,
    '_blank'
  );
}

export function clearGroupCodesToSend(groupCodes) {
  const ALL_GROUP_CODE = -1;
  if (groupCodes.indexOf(ALL_GROUP_CODE) !== -1) {
    return [];
  }
  return groupCodes;
}

export function parseAccessToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ***********************************************************************
//
// transaction description parser
//
// ***********************************************************************

export function parseDescriptionTemplate(templates, t, tId, metadata) {
  const parseSinglePrefixes = (prefixes, hookValue) => {
    if (prefixes.length === 0) return hookValue;

    let finalText = hookValue;

    // todo: throw exception when exception occured!

    if (prefixes.includes('!')) {
      finalText = t(finalText);
    }
    if (prefixes.includes('$')) {
      finalText = formatMoneyAmount(finalText);
    }
    if (prefixes.includes('*')) {
      finalText = getTimeZoneFormattedTime(finalText);
    }
    if (prefixes.includes('@')) {
      finalText = getTimeZoneFormattedDate(finalText);
    }
    if (prefixes.includes('?')) {
      finalText = finalText || t('Unknown');
    }
    if (prefixes.includes('&')) {
      finalText = finalText ? `(${finalText})` : '';
    }

    return finalText;
  };

  const getParsedHookValue = (matchedValue, hookName, hookValue) => {
    if (!hookValue) return '';
    const prefixes = matchedValue.substr(1, matchedValue.indexOf(hookName) - 1);
    if (matchedValue.includes('#')) {
      return []
        .concat(hookValue)
        .map((elem) => parseSinglePrefixes(prefixes, elem))
        .join(`${t('Comma')} `);
    }
    return parseSinglePrefixes(prefixes, hookValue);
  };

  if (!templates?.length || !tId) return '';
  let parsedDescription = templates.find((obj) => obj.id === tId)?.body;
  if (!parsedDescription) return `No related template found with ${tId}`;

  const clearMetadata = [];
  metadata.forEach((hookObj) => {
    const hookIndex = clearMetadata.findIndex(
      (obj) => obj.hook === hookObj.hook
    );
    if (hookIndex === -1) {
      clearMetadata.push({ ...hookObj });
    } else {
      clearMetadata[hookIndex].value = []
        .concat(clearMetadata[hookIndex].value)
        .concat(hookObj.value);
    }
  });

  clearMetadata.forEach((hookObj) => {
    const regexStr = `\\{[^(\\}a-zA-Z)]*${hookObj.hook}\\}`;
    const regex = new RegExp(regexStr, 'g');
    if (parsedDescription.match(regex)) {
      const matchedString = parsedDescription.match(regex)[0];
      parsedDescription = parsedDescription.replace(
        regex,
        getParsedHookValue(matchedString, hookObj.hook, hookObj.value)
      );
    } else {
      console.log(`${regex} not found!`);
    }
  });
  return parsedDescription.trim();
}

export function deepCompare(obj1, obj2) {
  // Check if both objects are null or undefined
  if (obj1 == null || obj2 == null) {
    return obj1 === obj2;
  }

  // Check if both objects are arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    for (let i = 0; i < obj1.length; i++) {
      if (!deepCompare(obj1[i], obj2[i])) {
        return false;
      }
    }
    return true;
  }

  // Check if both objects are objects
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }

  // Check if both objects are primitive types
  return obj1 === obj2;
}
