const paddingDesktop = {
  mainContent: '18px 24px',
  aryCard: '24px',
  aryWrapper: '18px 24px',
  aryWrapperY: '18px',
  aryWrapperX: '24px',
  gridRowSpacing: '24px',
  gridSmallRowSpacing: '18px',
  gridColumnSpacing: '16px',
  gridSmallColumnSpacing: '12px',
  aryAlertBottom: '32px'
};

export default paddingDesktop;
